
import { computed } from "vue";

export default {
  props: ["obj"],
  setup(props, ctx) {

    const getAvatar = computed(() => {
      return process.env.VUE_APP_BUCKET_URL + '/' + props.obj.pictureurl
    })

    return {
    getAvatar,
    };
  },
};
