<template>
  <div class="container">
    <p class="bio">Experiências</p>

    <div class="experiences">
      <div class="card-experience">

        <div class="item" v-for="experience in obj?.experiences">
          <h1>{{ experience?.institution }}</h1>
          <p class="experience-address"> {{ experience?.city }} - {{ experience?.state }} </p>
          <p class="experience-startyear"> {{ experience?.startyear }} -  

            <div class="experience-endyear" v-if="!experience.current">
              {{ experience.endyear }}
            </div>
            <div v-else>
              Atualmente
            </div>
          </p>  
        </div>

      </div> 
    </div>

    <button class="btn-edit">
      <img src="../../../../assets/icons/edit_pencil.png" alt="" />
    </button>

  </div>
</template>

<script>
import { ref } from "vue";

export default {
  props: ["obj"],
  setup(props, ctx) {

    const dataProfile = ref({})

    return { dataProfile };
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  align-items: center;
  padding: 50px 36px;
  border: 1px solid #ff6a33;
  border-radius: 8px;
  gap: 50px;
  .experiences {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    gap: 30px;
    .card-experience{
      width: 100%;
      display:flex;
      gap: 12px;
    }
    .item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 23px;
      width: calc(100% / 3);
      min-height: 113px;
      background: white;
      border-radius: 8px;
      border: 1px solid #f2f2f2;
      box-sizing: border-box;
      box-shadow: 0px 0px 16px rgba(61, 71, 102, 0.08);
      cursor: pointer !important;
      &:hover{
        border-color:#ff6a33 ;
      }
      h1 {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        color: #4f4f4f;
        margin: 0;
        padding: 0;
      }
      p {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #4f4f4f;
      }
      .experience-startyear {
        display: flex;
        flex-direction: row;
        color: #bdbdbd;
        gap: 3px;
      }
    }
  }
  .btn-edit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background: #ff6a33;
    cursor: pointer;
    margin-left: 16px;
    height: 30px;
    padding: 0 12px;
    border: none;
    border-radius: 32px;
  }
  .bio {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #bdbdbd;
  }
  .description {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4f4f4f;
  }
  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  .txt-gray {
    color: #828282;
  }
}
</style>
