<template>
    <div class="card-biography">
        <div class="container-biography">

            <div class="biography-info">
                <Skeleton class="profile-header" width="60px" height="16px" />
            </div>

            <div class="content">

                <div class="content-biography">
                    <Skeleton class="biography" width="700px" height="18px" />
                    <Skeleton class="biography" width="700px" height="18px" />
                    <Skeleton class="biography" width="700px" height="18px" />
                    <Skeleton class="biography" width="140px" height="18px" />
                </div>

            </div>

        </div>

        <div class="biography-actions">
            <Skeleton class="biography-edit" width="30px" height="30px" />
        </div>

    </div>
    
</template>

<script>
// import { onMounted, ref } from "vue";
// import A from "./A";

export default {
    //   props: ["",],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
        }
    },
};
</script>


<style lang="scss" scoped>
.card-biography {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 140px;
    padding: 100px 36px;
    border: 1px solid #bdbdbd;
    border-radius: 8px;
    .container-biography {
        display: flex;
        align-items: center;
        gap: 50px;
        
        .content {
            display: flex;
            flex-direction: row;
            border-radius: 8px;
            gap: 16px;
            .content-biography {
                display: flex;
                flex-direction: column;
                border-radius: 8px;
                gap: 5px;
            }
        }
    }
    .biography-actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 11px;
        .biography-edit {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
        }
    }
}

</style>