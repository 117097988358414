<template>
    <div class="card-video">
        <div class="container-video">

            <div class="video-action-1">
                <Skeleton class="video-see" width="120px" height="40px" />
            </div>

            <div class="video-action-2">
                <Skeleton class="video-edit" width="120px" height="40px" />
            </div>

        </div>

    </div>
    
</template>

<script>
// import { onMounted, ref } from "vue";
// import A from "./A";

export default {
    //   props: ["",],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
        }
    },
};
</script>


<style lang="scss" scoped>
.card-video {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    padding: 100px 36px;
    border: 1px solid #bdbdbd;
    border-radius: 8px;
    .container-video {
        display: flex;
        align-items: center;
        gap: 20px;

        .video-action-1 {
            .video-see {
                width: 120px;
                height: 40px;
                border-radius: 33px;
                overflow: hidden;
            }
        }

        .video-action-2 {
            .video-edit {
                width: 120px;
                height: 40px;
                border-radius: 33px;
                overflow: hidden;
            }
        }
    }
}


</style>