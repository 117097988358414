<template>
    <div class="container">      
        
        <ProfileLoader />
       
        <RegisterLoader />

        <BiographyLoader />
        
        <VideoLoader />

        <LocalLoader />

        <ExperiencesLoader />

        <PublicationsLoader />

        <TreatedDiseasesLoader />

        <LanguageLoader />

        <AwardsLoader />

        <AcademicEducationLoader />

        <CertificatesLoader />

    </div>  
</template>

<script>
import ProfileLoader from "./components/Profile/ProfileLoader.vue"
import RegisterLoader from "./components/Register/RegisterLoader.vue"
import BiographyLoader from "./components/Biography/BiographyLoader.vue"
import VideoLoader from "./components/Video/VideoLoader.vue"
import LocalLoader from "./components/Local/LocalLoader.vue"
import ExperiencesLoader from "./components/Experiences/ExperiencesLoader.vue"
import PublicationsLoader from "./components/Publications/PublicationsLoader.vue"
import TreatedDiseasesLoader from "./components/TreatedDiseases/TreatedDiseasesLoader.vue"
import LanguageLoader from "./components/Lan.guage/LanguageLoader.vue" 
import AwardsLoader from "./components/Awards/AwardsLoader.vue" 
import AcademicEducationLoader from "./components/AcademicEducation/AcademicEducationLoader.vue" 
import CertificatesLoader from "./components/Cer.tificates/CertificatesLoader.vue"

export default {
    //   props: ["",],
    components: {
        ProfileLoader,
        RegisterLoader,
        BiographyLoader,
        VideoLoader,
        LocalLoader,
        ExperiencesLoader,
        PublicationsLoader,
        TreatedDiseasesLoader,
        LanguageLoader,
        AwardsLoader,
        AcademicEducationLoader,
        CertificatesLoader
    },
    setup(props, { emit }) {
        // const bool = ref(false);

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
        }
    },
};
</script>


<style lang="scss" scoped>
.container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

</style>