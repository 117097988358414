<template>
    <div class="card-register">
        <div class="container-register">

            <div class="register-info">
                <Skeleton class="register-header" width="60px" height="16px" />
            </div>

            <div class="content">

                <div class="content-crm">
                    <Skeleton class="crm" width="160px" height="40px" />
                </div>

                <div class="content-crm">
                    <Skeleton class="crm" width="160px" height="40px" />
                </div>

            </div>

        </div>

        <div class="register-actions">
            <Skeleton class="register-edit" width="30px" height="30px" />
        </div>

    </div>
    
</template>

<script>
// import { onMounted, ref } from "vue";
// import A from "./A";

export default {
    //   props: ["",],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
        }
    },
};
</script>


<style lang="scss" scoped>
.card-register {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 102px;
    padding: 0px 36px;
    border: 1px solid #FF6A33;
    border-radius: 8px;
    .container-register {
        display: flex;
        align-items: center;
        gap: 50px;
        
        .content {
            display: flex;
            flex-direction: row;
            border-radius: 8px;
            gap: 16px;
        }
    }
    .register-actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 11px;
        .register-edit {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
        }
    }
}


</style>