<template>
  <div class="card-biography">

    <p class="bio">Biografia</p>

    <p class="biography"> {{ obj?.biography }} </p>

    <button class="btn-edit">
      <img src="../../../../assets/icons/edit_pencil.png" alt="" />
    </button>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import { useRouter } from "vue-router"

export default {
  props: ["obj"],

  //components: {},
  setup(props, ctx) {
    const dataProfile = ref({})

    return {
    dataProfile,
    };
  },
};
</script>

<style lang="scss" scoped>
.card-biography {
  display: flex;
  align-items: center;
  padding: 50px 36px;
  border: 1px solid #bdbdbd;
  border-radius: 8px;
  gap: 50px;
  button {
    height: 30px;
    padding: 0 12px;
    border: none;
    border-radius: 32px;
  }
  .btn-edit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background: #ff6a33;
    cursor: pointer;
    margin-left: 16px;
  }
  .bio {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #bdbdbd;
  }
  .biography {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4f4f4f;
  }
}
</style>
