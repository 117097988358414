<template>
  <div class="card-video">
    <button class="view-video"><img src="../../../../assets/icons/video.png" alt="" />Ver vídeo</button>
    <button class="edit-video"><img src="../../../../assets/icons/edit_pencil.png" alt="">Editar vídeo</button>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.card-video {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../../../assets/images/video-1.png");
  height: 400px;
  width: 100%;
  border-radius: 8px;
  gap: 16px;
  button{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14;
    line-height: 18px;
    border: none;
    height: 33px;
    gap: 12px;
    cursor: pointer;
  }
  .view-video{
    display:flex;
    justify-content: flex-start;
    background: #bf8b79;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #bf8b79, #d18c72, #d0a18e);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #bf8b79, #d18c72, #d0a18e); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    width: 114px;
    /* background: rgba(0, 0, 0, .05); */
    backdrop-filter: blur(81px);
    border-radius: 30px;
  }
  .edit-video{
    background: #FF6A33;
    border-radius: 33px;
    width: 141px;
  }
  h1 {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    color: #2d313d;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    /* line-height: 24px; */
  }
  .profile-span {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 190.94%;
    color: #bdbdbd;
  }
  .content {
    margin-left: 8px;
  }
  .h1-gray {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #bdbdbd;
  }
}
.color-gray {
  color: #828282;
}
.color-orange {
  color: #ff6a33;
}
</style>