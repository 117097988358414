<template>
  <div class="card-formation">
    <p class="bio">Formação acadêmica</p>

    <div class="formacao-academica">
      <div class="item" v-for="formation in obj?.formations" :key="formation.documentid">

        <div class="formation-photo"> 
          <img class="p-avatar" :src="getFormation (formation?.documenturl)" alt="" />
        </div>

        <div class="formation-infos">
          <p class="formation-header">Medicina</p>
          <p class="formation-info">UNIVERSIDADE FEDERAL DO PIAUÍ - MEDICINA</p>
          <p class="formation-year">Formação em 2010</p>  
        </div>

      </div>
    </div>

    <div class="btn-formation">
      <button class="btn-edit">
        <img src="../../../../assets/icons/edit_pencil.png" alt="" />
      </button>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  props: ["obj"],
  setup(props, ctx) { 

    const dataProfile = ref({})

    const getFormation = (documenturl) => {

        return documenturl ? process.env.VUE_APP_BUCKET_URL + '/' + documenturl : null;
      }

    return { dataProfile, getFormation };
  },
};
</script>

<style lang="scss" scoped>
.card-formation {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 50px 36px;
  border: 1px solid #ff6a33;
  border-radius: 8px;
  .formacao-academica {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    padding: 30px 50px;
    .item {
      display: flex;
      flex-direction: row;
      min-width: 200px;
      max-width: 600px;
      gap: 20px;

      .formation-photo {
        max-width: 200px;
        .p-avatar {
          border-radius: 16px 16px 16px 16px;
          width: 100%;
          height: 100%;
        }
      }
      .formation-infos {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        
        .formation-header {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
        .formation-info {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #828282;
        }
        .formation-year{
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #828282;
        }
      }
    }
  }

  .btn-formation {
    display: flex;
    justify-content: flex-end !important;
    .btn-edit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    padding: 0 12px;
    border: none;
    border-radius: 32px;
    background: #ff6a33;
    cursor: pointer;
    margin-left: 16px;
    }
  }

  .bio {
    max-width: 103px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #bdbdbd;
  }
}
</style>
