<template>
    <div class="card-local">
        <div class="container-local">

            <div class="local-info">
                <Skeleton class="local-header" width="160px" height="16px" />
            </div>

            <div class="content">

                <div class="content-local-photo">
                    <Skeleton class="local" width="180px" height="180px" />
                </div>

                <div class="content-local">
                    <Skeleton class="local" width="160px" height="20px" />
                    <Skeleton class="local" width="120px" height="16px" />
                </div>

            </div>

        </div>

        <div class="local-actions">
            <Skeleton class="local-edit" width="30px" height="30px" />
        </div>

    </div>
    
</template>

<script>
// import { onMounted, ref } from "vue";
// import A from "./A";

export default {
    //   props: ["",],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
        }
    },
};
</script>


<style lang="scss" scoped>
.card-local {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 300px;
    padding: 0px 36px;
    border: 1px solid #FF6A33;
    border-radius: 8px;
    .container-local {
        display: flex;
        align-items: center;
        gap: 50px;
        
        .content {
            display: flex;
            flex-direction: row;
            align-items: center;
            border-radius: 8px;
            gap: 16px;
            .content-local {
                display: flex;
                flex-direction: column;
                gap: 8px;
            }
        }
    }
    .local-actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 11px;
        .local-edit {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
        }
    }
}


</style>