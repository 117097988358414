<template>
  <div class="p-3 pt-0">
    <div class="limit p-3">
      <h1 class="title-header">Perfil profissional</h1>

      <Loader v-if="isLoading"/>

      <div class="Profile-infos" v-else>

        <!-- PROFILE COMPONENT -->
        <Profile :obj="{firstname: dataProfile.firstname, lastname: dataProfile.lastname, description: dataProfile.description, pictureurl: dataProfile.pictureurl}"/>

        <!-- REGISTER COMPONENT -->
        <Register :obj="{councilprofessionalsList: dataCouncil} "/>

        <!-- BIOGRAPHY COMPONENT -->
        <Biography :obj="{biography: dataProfile.biography}" />

        <!-- VIDEO COMPONENT -->
        <Video />

        <!-- LOCAL COMPONENT -->
        <Local :obj="{places: dataProfile.placesList}"/>

        <!-- EXPERIENCES COMPONENT -->
        <Experiences :obj="{experiences: dataProfile.experiencesList}"/>

        <!-- PUBLICATIONS COMPONENT -->
        <Publications />

        <!-- TREATEDDISEASES COMPONENT -->
        <TreatedDiseases :obj="{diseases: dataProfile.diseasesList}" />

        <!-- LANGUAGE COMPONENT -->
        <Language :obj="{languages: dataProfile.languagesList}" />

        <!-- AWARDS COMPONENT -->
        <Awards :obj="{awards: dataProfile.awardsList}"/>

        <!-- ACADEMICEDUCATION COMPONENT -->
        <AcademicEducation :obj="{formations: dataProfile.formationsList}" />

        <!-- CERTIFICATES COMPONENT -->
        <Certificates />
        
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import actions from "./actions";
import Profile from "./components/Profile/Profile.vue";
import Register from "./components/Register/Register.vue";
import Biography from "./components/Biography/Biography.vue";
import Video from "./components/Video/Video.vue";
import Local from "./components/Local/Local.vue";
import TreatedDiseases from "./components/TreatedDiseases/TreatedDiseases.vue";
import Experiences from "./components/Experiences/Experiences.vue";
import Awards from "./components/Awards/Awards.vue";
import Language from "./components/Lan.guage/Language.vue";
import Publications from "./components/Publications/Publications.vue";
import AcademicEducation from "./components/AcademicEducation/AcademicEducation.vue";
import Certificates from "./components/Cer.tificates/Certificates.vue";
import Loader from "./Loader.vue";

export default {
  components: { 
    Profile,
    Register,
    Biography,
    Video,
    Local,
    TreatedDiseases,
    Experiences,
    Awards,
    Language,
    Publications,
    AcademicEducation,
    Certificates,
    Loader,
  },

  setup(props, ctx) {

    const isLoading = ref(true);
    const dataProfile = ref({});
    const dataCouncil = ref({});

    // onMounted(async () => {
    //   const res = await actions.ListProfessionalProfile();
    //   isLoading.value = true;
    //   console.log("ListProfessionalProfile", res)
    //   if('success' in res && res.success && 'profileList' in res && res.profileList.length){
    //     dataProfile.value = res.profileList[0]
    //     console.log(res.profileList[0])
    //   }
    //   isLoading.value = false;
    // });

    onMounted(async () => {
      isLoading.value = true;
      const res = await actions.ListCouncil();
      const resProfessionalProfile = await actions.ListProfessionalProfile();
      console.log("ListCouncil", res)
      if('success' in res && res.success && 'councilprofessionalsList' in res && res.councilprofessionalsList.length){
        dataCouncil.value = res.councilprofessionalsList
        console.log(res.councilprofessionalsList)
      }
      if('success' in resProfessionalProfile && resProfessionalProfile.success && 'profileList' in resProfessionalProfile && resProfessionalProfile.profileList.length){
        dataProfile.value = resProfessionalProfile.profileList[0]
        console.log(resProfessionalProfile.profileList[0])
      }

      isLoading.value = false;
    });


    return {
    dataProfile,
    dataCouncil,
    isLoading
    };
  },
};
</script>

<style lang="scss" scoped>
.limit {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 100%;
}
.Profile-infos {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.btn-header {
  background: white;
  box-shadow: 0px 0px 16px rgba(61, 71, 102, 0.08);
  backdrop-filter: blur(8px);
  border-radius: 4px;
  color: #ff6a33;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  padding: 4px 8px;
  margin-left: 8px;
}
</style>