<template>
  <div class="card-locals">
    <p class="bio">Seu local de atendimento</p>
    <div class="local-atendimento">
      
      <div class="item" v-for="local in obj?.places" :key="local.id">
        
        <div class="local-photo">

          <img class="p-avatar" :src="getImage (local?.pictureUrl)" alt="" />

        </div>

        <div class="local-infos">
          <p class="local-header">{{ local?.name }} </p>
          <p class="local-address">{{ local?.address }}</p>
          <p class="local-neighborhood">{{ local?.neighborhood }} - Cep: {{ local?.postalCode }}</p>
        </div>

      </div>  
    </div>

    <button class="btn-edit">
      <img src="../../../../assets/icons/edit_pencil.png" alt="" />
    </button>
  </div>
</template>

<script>

import { ref } from "vue";
import UploadDefault from "@/assets/icons/upload-default.png"

export default {
  props: ["obj"],
  setup(props, ctx) { 

    const dataProfile = ref({})

    const getImage = (pictureUrl) => {
      if (pictureUrl) {
        return process.env.VUE_APP_BUCKET_URL + '/' + pictureUrl
      }

      return UploadDefault;
    }

    return { dataProfile, getImage };
  },
};
</script>

<style lang="scss" scoped>
.card-locals {
  display: flex;
  align-items: center;
  padding: 50px 36px;
  border: 1px solid #FF6A33;
  border-radius: 8px;
  gap: 50px;
  .local-atendimento{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    gap: 30px;
    padding: 30px 50px;

    .item {
      display: flex;
      flex-direction: row;
        .local-photo {
          display: flex;
          min-width: 200px;
          max-width: 200px;
          padding-right: 20px;
          .p-avatar {
            border-radius: 16px 16px 16px 16px;
            width: 100%;
            height: 100%;
          }
        }

        .local-infos {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .local-header {
            font-size: 18px;
            line-height: 24px;
          }
          .local-address {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #828282;
          }
          .local-neighborhood {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #828282;
          }
        }
      }
    }
  button {
    height: 30px;
    padding: 0 12px;
    border: none;
    border-radius: 32px;
  }
  .btn-edit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background: #ff6a33;
    cursor: pointer;
    margin-left: 16px;
  }
  .bio {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #bdbdbd;
  }
  .description {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4f4f4f;
  }
}
</style>
