<template>
    <div class="card-education">
        <div class="container-education">

            <div class="education-info">
                <Skeleton class="education-header" width="60px" height="16px" />
                <Skeleton class="education-header" width="80px" height="16px" />
            </div>

            <div class="content">

                <div class="content-education-photo">
                    <Skeleton class="education" width="200px" height="160px" />
                </div>

                <div class="content-education">
                    <Skeleton class="education" width="80px" height="20px" />
                    <Skeleton class="education" width="180px" height="16px" />
                    <Skeleton class="education" width="120px" height="16px" />
                </div>

            </div>

        </div>

        <div class="education-actions">
            <Skeleton class="education-edit" width="30px" height="30px" />
        </div>

    </div>
    
</template>

<script>
// import { onMounted, ref } from "vue";
// import A from "./A";

export default {
    //   props: ["",],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
        }
    },
};
</script>


<style lang="scss" scoped>
.card-education {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 300px;
    padding: 0px 36px;
    border: 1px solid #FF6A33;
    border-radius: 8px;
    .container-education {
        display: flex;
        align-items: center;
        gap: 70px;

        .education-info {
            display: flex;
            flex-direction: column;
            gap: 8px;;
        }
        
        .content {
            display: flex;
            flex-direction: row;
            align-items: center;
            border-radius: 8px;
            gap: 16px;
            .content-education {
                display: flex;
                flex-direction: column;
                gap: 8px;
            }
        }
    }
    .education-actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 11px;
        .education-edit {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
        }
    }
}

</style>