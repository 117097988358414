<template>
    <div class="card-language">
        <div class="container-language">

            <div class="language-info">
                <Skeleton class="language-header" width="40px" height="16px" />
            </div>

            <div class="content">

                <div class="content-language">
                    <Skeleton class="language" width="100px" height="30px" />
                </div>

                <div class="content-language">
                    <Skeleton class="language" width="120px" height="30px" />
                </div>

                <div class="content-language">
                    <Skeleton class="language" width="80px" height="30px" />
                </div>

            </div>

        </div>

        <div class="language-actions">
            <Skeleton class="language-edit" width="30px" height="30px" />
        </div>

    </div>
    
</template>

<script>
// import { onMounted, ref } from "vue";
// import A from "./A";

export default {
    //   props: ["",],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
        }
    },
};
</script>


<style lang="scss" scoped>
.card-language {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 102px;
    padding: 0px 36px;
    border: 1px solid #FF6A33;
    border-radius: 8px;
    .container-language {
        display: flex;
        align-items: center;
        gap: 36px;
        
        .content {
            display: flex;
            flex-direction: row;
            border-radius: 8px;
            gap: 16px;
        }
    }
    .language-actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 11px;
        .language-edit {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
        }
    }
}
</style>