<template>
  <h1>Suas publicações</h1>
  <div class="container">
    <div class="item">
      <img src="../../../../assets/images/art-1.png" alt="" />
    </div>
    <div class="item">
      <img src="../../../../assets/images/art-2.png" alt="" />
    </div>
  </div>
  <footer>
    <div class="view-all">
      Veja todas sua publicações
      <img src="../../../../assets/icons/view-allpublications.png" alt="" />
    </div>
    <div class="add-publication">
      Adiconar publicação
      <img src="../../../../assets/icons/add-publication.png" alt="" />
    </div>
  </footer>
</template>

<script>
import { ref } from "vue";
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>

.container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  max-width: 1094px;
  gap: 28px;
  .item {
    // width: 100%;
    height: 200px;
    border-radius: 8px;
    box-sizing: border-box;
    box-shadow: 0px 0px 16px rgba(61, 71, 102, 0.08);
    cursor: pointer;
    &:hover{
        border-color:#ff6a33 !important ;
      }
  }
}

h1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #4f4f4f;
  margin: 0;
  padding: 0;
}
footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 23px;
  width: 100%;
  padding-right: 8px;
}
.view-all {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #BDBDBD;
  cursor: pointer;
}
.add-publication{
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #FF6A33;
  cursor: pointer;
}
.color-gray {
  color: #828282;
}
.color-orange {
  color: #ff6a33;
}
</style>
