<template>
    <div class="card-profile">
        <div class="container-profile">

            <div class="profile-photo">
                <Skeleton class="avatar" width="80px" height="80px" />
            </div>

            <div class="content">

                <div class="content-name">
                    <Skeleton class="name" width="220px" height="20px" />
                </div>

                <div class="content-description">
                    <Skeleton class="description" width="180px" height="18px" />
                </div>

            </div>

        </div>

        <div class="profile-actions">
            <Skeleton class="profile-pacient" width="144px" height="30px" />
            <Skeleton class="profile-edit" width="30px" height="30px" />
        </div>

    </div>

</template>

<script>
// import { onMounted, ref } from "vue";
// import A from "./A";

export default {
    //   props: ["",],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
        }
    },
};
</script>


<style lang="scss" scoped>
.card-profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 112px;
    padding: 16px 36px;
    background-image: linear-gradient( 68.4deg,  #e3e6ef,#e3e6ef, #e3e1e8, #e7dde0, #eee5e2, #edeae9 );
    backdrop-filter: blur(81.5485px);
    border-radius: 8px;
    margin-top: 20px;

    .container-profile {
        display: flex;
        align-items: center;

        .profile-photo {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            overflow: hidden;
        }

        .content {
            display: flex;
            flex-direction: column;
            padding-left: 12px;
            gap: 12px;
        }
    }

    .profile-actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 11px;
        .profile-edit {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
        }
    }
}


</style>