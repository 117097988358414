<template>
  <div class="card-biography">
    <p class="bio">Outros tipos de certificados</p>
    <div class="local-atendimento">
      <div class="flex w-full align-items-center" style="gap: 30px">
        <img src="../../../../assets/images/certificado_relacionamentos.png" alt="" />
        <div class="local">
          <p class="certificate-header">Cirurgia Geral</p>
          <p class="txt-gray">IAMSPE</p>
          <p class="txt-gray">Formação em 2013</p>
        </div>
      </div>
    </div>

    <button class="btn-edit">
      <img src="../../../../assets/icons/edit_pencil.png" alt="" />
    </button>
  </div>
</template>

<script>
// import { ref } from "vue";

export default {
  setup() {
    const items = [
      {
        img: "",
        label: "Relacionamentos humanos",
        local: "Escola da Mente Carlos Emanuel",
        course: "Curso de Psicologia",
        date: "Formação em 2009",
      },
    ];

    const imgToRequire = img => {
      return `require(${img})`
    }

    return { items, imgToRequire};
  },
};
</script>

<style lang="scss" scoped>
.card-biography {
  display: flex;
  align-items: center;
  padding: 50px 36px;
  border: 1px solid #ff6a33;
  border-radius: 8px;
  gap: 50px;
  .local-atendimento {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    gap: 30px;
    .local {
      .certificate-header {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
  button {
    height: 30px;
    padding: 0 12px;
    border: none;
    border-radius: 32px;
  }
  .btn-edit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background: #ff6a33;
    cursor: pointer;
    margin-left: 16px;
  }
  .bio {
    max-width: 103px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #bdbdbd;
  }
  .txt-gray {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #828282;
  }
}
</style>
