import professionalProfileService from "../core/services/professionalProfileService"
import { EmptyRequest } from "../core/grpc/generated/professionalProfile_pb.js"

const ListProfessionalProfile = async () => {
    try {
        const req = new EmptyRequest();
        const res = await professionalProfileService.listProfessionalProfile(req);

        return res;
        
    } catch (error) {
        console.log(error);
        return error;
    }
}

const ListCouncil = async () => {
    try {
        const req = new EmptyRequest();
        const res = await professionalProfileService.listCouncil(req);

        return res;
        
    } catch (error) {
        console.log(error);
        return error;
    }
}

export default {
    ListProfessionalProfile,
    ListCouncil,
}