<template>
    <div class="card-publications">
        <div class="container-publications">
            <Skeleton class="publications-header" width="120px" height="16px" />
        </div>

        <div class="publications-list">
            <Skeleton class="publications-1" width="540px" height="200px" />
            <Skeleton class="publications-2" width="540px" height="200px" />
        </div>

        <div class="publications-actions">
            <Skeleton class="publications-edit" width="160px" height="16px" />
            <Skeleton class="publications-edit" width="120px" height="16px" />
        </div>

    </div>

    
</template>

<script>
// import { onMounted, ref } from "vue";
// import A from "./A";

export default {
    //   props: ["",],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
        }
    },
};
</script>


<style lang="scss" scoped>
.card-publications {
    display: flex;
    flex-direction: column;
    height: 230px;
    gap: 10px;
    margin-bottom: 16px;
    .container-publications {
        display: flex;
    }
    .publications-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        .publications-1 {
            border-radius: 12px;
        }
        .publications-2 {
            border-radius: 12px;
        }
    }
    .publications-actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 20px; 
    }
}


</style>