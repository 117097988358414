<template>
    <div class="card-experiences">
        <div class="container-experiences">

            <div class="experiences-info">
                <Skeleton class="experiences-header" width="80px" height="16px" />
            </div>

            <div class="content">

                <div class="content-experiences">
                    <Skeleton class="experiences" width="260px" height="120px" />
                </div>

                <div class="content-experiences">
                    <Skeleton class="experiences" width="260px" height="120px" />
                </div>

            </div>

        </div>

        <div class="experiences-actions">
            <Skeleton class="experiences-edit" width="30px" height="30px" />
        </div>

    </div>
    
</template>

<script>
// import { onMounted, ref } from "vue";
// import A from "./A";

export default {
    //   props: ["",],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
        }
    },
};
</script>


<style lang="scss" scoped>
.card-experiences {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 230px;
    padding: 0px 36px;
    border: 1px solid #FF6A33;
    border-radius: 8px;
    .container-experiences {
        display: flex;
        align-items: center;
        gap: 50px;
        
        .content {
            display: flex;
            flex-direction: row;
            border-radius: 8px;
            gap: 16px;
        }
    }
    .experiences-actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 11px;
        .experiences-edit {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
        }
    }
}


</style>