<template>
  <div class="container">
    <p class="bio">Idioma</p>
    <div class="experiences">
      <div class="card-experience">
        <div class="list-cards">
          <div class="item" v-for="language in obj?.languages">
            <p>{{ language.idiom }}</p>
            <img
              class="pointer"
              src="../../../../assets/icons/close_application.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>

    <button class="btn-edit">
      <img src="../../../../assets/icons/add-white.png" alt="" />
    </button>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  props: ["obj"],
  setup() {
    const dataProfile = ref({})

    // const removeItem = (item) => {
    //   items.value = items.value.filter((it) => it !== item);
    // };
    

    return { dataProfile };
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  align-items: center;
  padding: 50px 36px;
  border: 1px solid #ff6a33;
  border-radius: 8px;
  gap: 30px;
  .experiences {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    gap: 30px;
    .card-experience {
      width: 100%;
      display: flex;
      gap: 12px;
    }
    .item {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding: 23px;
      width: auto;
      max-width: calc(100% / 5 - 10px);
      height: 30px;
      background: white;
      border-radius: 8px;
      border: 1px solid #f2f2f2;
      box-sizing: border-box;
      box-shadow: 0px 0px 16px rgba(61, 71, 102, 0.08);
      
      h1 {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #4f4f4f;
        margin: 0;
        padding: 0;
      }
      p {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #4f4f4f;
      }
      .date {
        color: #bdbdbd;
      }
    }
    .list-cards {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      gap: 12px;
      border-radius: 8px;
      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border: 1px solid #f2f2f2;
        border-radius: 16px;
        padding: 9px 16px;
      }
    }
  }
  button {
    height: 30px;
    padding: 0 12px;
    border: none;
    border-radius: 32px;
  }
  .btn-edit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background: #ff6a33;
    cursor: pointer;
    margin-left: 16px;
  }
  .bio {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #bdbdbd;
  }
  .description {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4f4f4f;
  }
  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  .txt-gray {
    color: #828282;
  }
}
.mt-16 {
  margin-top: 16px;
}
.color-gray {
  color: #828282;
}
.color-orange {
  color: #ff6a33 !important;
}
.pointer {
  cursor: pointer;
}
</style>
