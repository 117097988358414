<template>

<div class="card-register"> 

    <p class="bio">Registros</p>

    <div class="container">
      
      <div class="card-data" v-for="dataCouncil in obj?.councilprofessionalsList" :key="dataCouncil?.councilid">

        <span class="council-crm"> {{ dataCouncil?.council }} </span>
        <span class="council-number"> {{ dataCouncil?.number }} </span>
        <span class="council-state"> {{ dataCouncil?.state }} </span>

        <div class="council-status">
          <img v-if="dataCouncil?.status === 'Ativo'" src="../../../../assets/icons/check_v2.png" alt="" />
        </div>

      </div> 

    </div>

    <button class="btn-edit">
      <img src="../../../../assets/icons/edit_pencil.png" alt="" />
    </button>

  </div>  
</template>

<script>

import { ref } from "vue";

export default {
  props: ["obj"],
  setup(props, ctx) {
    const dataProfile = ref({})

    const dataCouncil = ref({})

    return { dataProfile, dataCouncil };
  },
};

</script>

<style lang="scss" scoped>

.card-register {
  display: flex;
  align-items: center;
  padding: 30px 36px;
  border: 1px solid #bdbdbd;
  border-radius: 8px;
  gap: 50px;
  .container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 16px;
  border-radius: 8px;
    .card-data{
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #F2F2F2;
      border-radius: 8px;
      height: 40px;
      padding-right: 12px;
      padding-left: 22px;

      .council-crm {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        padding-right: 5px;
      }
      .council-number {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        padding-right: 5px;
      }

      .council-state {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        padding-right: 30px;
      }
      .council-status {
        display: flex;
        align-content: center;
      }
    }
  }
  .btn-edit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background: #ff6a33;
    cursor: pointer;
    margin-left: 16px;
    height: 30px;
    padding: 0 12px;
    border: none;
    border-radius: 32px;
  }
  .bio {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #bdbdbd;
  }
}
.color-gray {
  color: #828282;
}
.color-orange {
  color: #ff6a33;
}


</style>
